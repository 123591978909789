import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'
import { RichText } from 'prismic-reactjs'
import { Row, Col } from 'react-bootstrap'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'
import Attachment from '../components/Attachment'
import TwoColSection from '../components/TwoColSection'
import SpotifyEmbed from '../components/SpotifyEmbed'
import SEO from '../components/seo'

const AnleitungTemplate = ({ data }) => {
  
  let duration = 0
  const anleitungsData = data.prismicAnleitung.data
  const goal = anleitungsData.goal.raw
  const comments = anleitungsData.comments.raw
  const description = anleitungsData.description.raw
  
  const playlistEmbed = (
    <div dangerouslySetInnerHTML={{ __html: anleitungsData.playlist_embed }} />
    )
    
    const playlistLink = anleitungsData.play_list
    const steps = anleitungsData.steps
    
    const stepNamesList = steps.map((stepName) => {
      if (stepName.active === true || stepName.active === null) {
        duration += stepName.duration_mm
      return (
        <li className="fs-3" key={stepName.index}>
          <Row>
            <Col xs={12}>
              <h3>
                {stepName.name.text} ({stepName.duration_mm}')
              </h3>
              <p className="fs-4">
                <RichText render={stepName.text.raw} />
              </p>
              {stepName.notes1 ? (
                <>
                  <h5>Notizen:</h5>
                  <p className="fs-6">{stepName.notes1}</p>
                </>
              ) : null}
            </Col>
            <Col xs={12}>
              {stepName.music ? (
                <p className="d-print-none">
                  <SpotifyEmbed wide embed_url={stepName.music.embed_url} />
                </p>
              ) : null}
              {stepName.track ? <p>Track: {stepName.track}</p> : null}
            </Col>
          </Row>
        </li>
      )
    }
  })

  const leftColContent = (
    <>
      <h4>Ziel:</h4>
      <p className="fs-4">
        <RichText render={goal} />
      </p>
    </>
  )
  const rightColContent = (
    <>
      <ul className="fs-4">
        {duration > 0 ? (
          <li>
            <strong>Gesamtdauer:</strong> {duration} Min.
          </li>
        ) : null}
        {anleitungsData.subject ? (
          <li>
            <strong>Thema:</strong> {anleitungsData.subject}
          </li>
        ) : null}
        {anleitungsData.sequence ? (
          <li>
            <strong>Sequenz:</strong> {anleitungsData.sequence}
          </li>
        ) : null}
        {/* {data.prismicAnleitung.data.attachment.url !== null ? <Attachment attachment={data.prismicAnleitung.data.attachment} /> : null} */}
        {anleitungsData.playlist_name ? (
          <li>
            <strong>Playlist Name:</strong> {anleitungsData.playlist_name}
          </li>
        ) : null}
      </ul>
      <p className="d-print-none">
        {playlistLink ? (
          <SpotifyEmbed wide embed_url={playlistLink.embed_url} />
        ) : null}
      </p>
    </>
  )

  if (!data) return null

  return (
    <LayoutAnleitungen>
      <SEO index="noindex" />
      <Col className="p-4 bg-light">
        <Row className="p-4 mt-4 mt-md-5 mb-4">
          <Col>
            <RichText render={anleitungsData.title.raw} />
            <h4 className="mt-5">Beschreibung:</h4>
            <p className="fs-4">
              <RichText render={description} />
            </p>
          </Col>
        </Row>
        <Row className="mt-4 mt-md-5 mb-md-5 mb-4">
          <Col>
            <Row className="section-2">
              <Col>
                <TwoColSection
                  leftColContent={leftColContent}
                  rightColContent={rightColContent}
                />
                {anleitungsData.comments ? (
                  <Row>
                    <Col>
                      <h4>Kommentare:</h4>
                      <p className="fs-4">
                        <RichText render={comments} />
                      </p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
            <Row className="bg-secondary text-white p-4">
              <Col>
                <h2>Schritte</h2>
                <ol>{stepNamesList}</ol>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </LayoutAnleitungen>
  )
}

export const query = graphql`
  query anleitungQuery($id: String, $lang: String) {
    prismicAnleitung(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          raw
        }
        attachments1 {
          name
          uri {
            url
          }
        }
        goal {
          raw
        }
        comments {
          raw
        }
        description {
          raw
        }
        subject
        sequence
        playlist_name
        play_list {
          embed_url
        }
        steps {
          active
          duration_mm
          notes1
          track
          music {
            embed_url
          }
          name {
            text
          }
          text {
            raw
          }
        }
      }
    }
  }
`
export default withPrismicPreview(AnleitungTemplate, repositoryConfigs)
